/* eslint-disable camelcase */
import { useApolloClient } from '@apollo/client';
import { DEFAULT_TITLE, Head } from 'components/common/Head';
import { PageContentWrapper } from 'components/layouts/PageContentWrapper/PageContentWrapper';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { isClientSide } from 'utils/isClientSide';
import { MainLayoutContainer } from './MainLayout.styles';
import { MainLayoutProps } from './MainLayout.types';
import { Spacer } from 'components/layouts/Spacer';
import Router from 'next/router';

const handleStart = () => NProgress.start();
const handleStop = () => NProgress.done();

Router.events.on('routeChangeStart', handleStart);
Router.events.on('routeChangeComplete', handleStop);
Router.events.on('routeChangeError', handleStop);
NProgress.configure({ showSpinner: true });

const useResetBFCache = () => {
  const { me } = useMe();
  const { id: currentUserId } = me ?? {};
  const store = useApolloClient();

  useEffect(() => {
    const resetCache = (e: PageTransitionEvent) => {
      if (e.persisted && !currentUserId) {
        store.cache.reset();
      }
    };
    if (isClientSide()) {
      window.addEventListener('pageshow', resetCache);
    }

    return () => {
      if (isClientSide()) {
        window.removeEventListener('pageshow', resetCache);
      }
    };
  }, [currentUserId, store]);
};

const useTrackUserPageOpened = () => {
  const { trackUserPageOpened } = useDive();
  useEffect(() => {
    trackUserPageOpened();
  }, [trackUserPageOpened]);
};

export const MainLayout = ({
  children,
  title = DEFAULT_TITLE,
  fullWidth,
  noHorizontalPadding,
  noBottomMargin,
}: MainLayoutProps) => {
  useTrackUserPageOpened();
  useResetBFCache();

  return (
    <MainLayoutContainer>
      <Head title={title} />
      <PageContentWrapper fullWidth={fullWidth} noHorizontalPadding={noHorizontalPadding}>
        {children}
        {!noBottomMargin && <Spacer height={80} />}
      </PageContentWrapper>

      <div id="modal-root" />
    </MainLayoutContainer>
  );
};
