import { Play } from '@hiberworld/icons';
import Button from 'components/legacy/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { Label } from 'components/typography';
import Image from 'next/image';
import styled, { css, keyframes } from 'styled-components';

export const thumbnailFilter = css`
  filter: saturate(140%) contrast(110%) brightness(105%);
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  overflow: hidden;
  aspect-ratio: 16 / 9;
  transition: all 0.25s ease-out;
`;

export const Thumbnail = styled(Image).attrs({
  fill: true,
  sizes:
    '(max-width: 568px) calc(50vw - 42px), (max-width: 1024px) calc(33vw - 72px), (max-width: 1460px) calc(25vw - 85px), 341px',
})`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  object-fit: cover;
  ${thumbnailFilter}
  border-radius: ${({ theme }) => theme.borderRadius.large};
  transition: all 0.25s ease-out;

  &:hover {
    transition: all 0.4s ease-out;
    transform: scale(1.1);
  }
`;

export const MetaInfoWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: auto;
  margin-top: 0.5rem;
  width: 100%;
`;

export const Title = styled(Label).attrs({ size: 'large' })`
  margin-top: 0;
  color: ${({ theme }) => theme.primaryColor.white};
  display: inline-block;
  transition: 0.1s;
  cursor: pointer;
  line-height: 20px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:link,
  &:visited {
    color: ${({ theme }) => theme.primaryColor.white};
  }
  &:hover {
    color: ${({ theme }) => theme.primaryColor.appLightGray};
  }
`;

export const Author = styled(Label).attrs({ size: 'medium' })`
  color: ${({ theme }) => theme.primaryColor.mediumGray};
  width: 100%;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor.textGray};
  }
`;

export const ProfileImage = styled(Image)`
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;

export const Overlay = styled(FlexBox)`
  font-size: ${({ theme }) => theme.typography.label.specialSmall.size};
  line-height: ${({ theme }) => theme.typography.label.specialSmall.lineHeight};
  font-weight: ${({ theme }) => theme.typography.label.specialSmall.weight};
  background: rgba(27, 28, 32, 0.3);
  border-radius: 0.25rem;
  padding: 0.25rem;
  padding-right: 0.5rem;
  color: ${({ theme }) => theme.primaryColor.white};
  position: absolute;
  bottom: 8px;
  left: 8px;
`;
const fadeIn = keyframes`
  from { opacity: 0; transform: translateX(-30px) }
    to { opacity: 1; transform: translateX(0) }
`;

export const PlayOverlay = styled(FlexBox)`
  &:hover {
    cursor: pointer;
    button {
      background-color: ${({ theme }) => theme.primaryColor.red};
    }
    span {
      display: none;
      @media (hover: hover) and (pointer: fine) {
        animation: ${fadeIn} ${({ theme }) => theme.transition.medium};
        animation-fill-mode: forwards;
        display: block;
      }
    }
    button {
      background-color: ${({ theme }) => theme.primaryColor.red};
      padding-right: 3.5rem;
    }
  }
`;

export const RoundButton = styled(Button)`
  border-radius: 2rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.primaryColor.red};
  transition: background-color ${({ theme }) => theme.transition.medium},
    padding ${({ theme }) => theme.transition.medium};

  @media (hover: hover) and (pointer: fine) {
    background-color: rgba(27, 28, 32, 0.3);
  }
  span {
    opacity: 0;
    display: none;
    margin-left: 5rem;
    position: absolute;
    margin-right: 0.5rem;
  }
`;

export const PlayIcon = styled(Play)`
  width: 2rem;
  height: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
