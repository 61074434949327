import React from 'react';
import NextHead from 'next/head';

export const DEFAULT_TITLE = 'HiberWorld: Play, Create and Share in the Metaverse.';
export const DEFAULT_DESCRIPTION =
  'Experience millions of virtual worlds or create your own, with or without code. Find out more in the Hiber3D Developer Portal.';
const DEFAULT_SOCIAL_ICON = 'https://cdn.hibervr.com/static/logos/hiber-fb-social.jpg';
const DEFAULT_SOCIAL_ACCOUNT = '@hiber3d';

interface HeaderProps {
  title?: string;
  referrer?: string | null;
}

export const Head = ({ title = DEFAULT_TITLE, referrer = null }: HeaderProps) => {
  const pageTitle = title;
  const socialTitle = title;

  return (
    <NextHead>
      <title>{pageTitle}</title>
      <meta charSet="utf-8" />
      {referrer && <meta name="referrer" content={referrer} />}

      {/* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */}
      <meta property="og:title" content={socialTitle} key="og:title" />
      <meta property="og:image" content={DEFAULT_SOCIAL_ICON} key="og:image" />
      <meta property="og:site_name" content="HiberWorld" key="og:site_name" />
      <meta property="og:description" key="og:description" content={DEFAULT_DESCRIPTION} />
      <meta property="og:url" key="og:url" content="https://hiberworld.com" />
      <meta property="og:type" key="og:type" content="website" />

      {/* <!-- Search Engine --> */}
      <meta name="description" key="description" content={DEFAULT_DESCRIPTION} />
      <meta name="image" key="image" content={DEFAULT_SOCIAL_ICON} />
      {/* <!-- Schema.org for Google --> */}
      <meta itemProp="name" key="name_prop" content="HiberWorld" />
      <meta itemProp="image" key="image_prop" content={DEFAULT_SOCIAL_ICON} />
      {/* <!-- Twitter --> */}
      <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" key="twitter:title" content={socialTitle} />
      <meta name="twitter:description" key="twitter:description" content={DEFAULT_DESCRIPTION} />
      <meta name="twitter:site" key="twitter:site" content={DEFAULT_SOCIAL_ACCOUNT} />
      <meta name="twitter:creator" content={DEFAULT_SOCIAL_ACCOUNT} />
      <meta name="twitter:image:src" key="twitter:image:src" content={DEFAULT_SOCIAL_ICON} />
      <meta name="twitter:image" key="twitter:image" content={DEFAULT_SOCIAL_ICON} />

      <link rel="icon" type="image/png" href="https://cdn.hibervr.com/static/icons/app/nobg/head-512.png" />

      <link rel="apple-touch-icon" sizes="76x76" href="https://cdn.hibervr.com/static/icons/icons/icon-76-dark.png" />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="https://cdn.hibervr.com/static/icons/icons/icon-120-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="128x128"
        href="https://cdn.hibervr.com/static/icons/icons/icon-128-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="https://cdn.hibervr.com/static/icons/icons/icon-152-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://cdn.hibervr.com/static/icons/icons/icon-180-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="https://cdn.hibervr.com/static/icons/icons/icon-192-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="512x512"
        href="https://cdn.hibervr.com/static/icons/icons/icon-512-dark.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="1024x1024"
        href="https://cdn.hibervr.com/static/icons/icons/icon-1024-dark.png"
      />

      {/* IOS Splash screen */}
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphone5_splash.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphone6_splash.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphoneplus_splash.png"
        media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphonex_splash.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphonexr_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/iphonexsmax_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/ipad_splash.png"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/ipadpro1_splash.png"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/ipadpro3_splash.png"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
      />
      <link
        href="https://cdn.hibervr.com/static/startup-image/ipadpro2_splash.png"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
      />

      <meta name="apple-mobile-web-app-status-bar-style" content="black" />

      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="HiberWorld" />

      <link rel="manifest" href="/manifest.json" />

      <meta httpEquiv="cache-control" content="max-age=0" />
      <meta httpEquiv="cache-control" content="no-cache" />
      <meta httpEquiv="expires" content="0" />
      <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
      <meta httpEquiv="pragma" content="no-cache" />

      <meta name="viewport" key="viewport" content="width=device-width, initial-scale=1" />
    </NextHead>
  );
};
